import React, { FC, useEffect } from 'react';

import {
  TeacherInput,
  TeacherSupplierInfoInput,
  TeacherType,
} from '@/apolloGenerated';
import {
  DeeplinkCopy,
  FormSection,
  handleNumberInput,
  useImageUploader,
} from '@/shared';
import {
  ImageUploader,
  NestedKeys,
  Spacer,
  TextField,
  useForm,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventFormProps {
  initValues: TeacherType;
  onChange(val: TeacherInput): void;
  errors?: Record<NestedKeys<TeacherInput>, string[]>;
}
export const TeacherForm: FC<EventFormProps> = ({
  errors,
  initValues,
  onChange,
}) => {
  const uploadImage = useImageUploader();
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<TeacherInput>(console.log, initValues);

  useEffect(() => {
    onChange(values);
  }, [values]);

  const updateSupplierInfo = function <
    T extends keyof TeacherSupplierInfoInput,
  >(key: T, value: TeacherSupplierInfoInput[T]) {
    setBatchValues({
      supplierInfo: {
        ...values.supplierInfo,
        [key]: value || null,
      },
    });
  };

  return (
    <div className={styles.teacherForm}>
      <FormSection title="Основная информация">
        <div className={styles.teacherForm__mainInfo}>
          <ImageUploader
            initValue={values.image}
            onChange={(val) => setBatchValues({ image: val })}
            error={errors?.image}
            uploadImageCallback={uploadImage}
          />
          <div>
            <TextField
              label="Название"
              size="large"
              fullWidth
              error={errors?.name}
              name="name"
              initValue={values.name}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <TextField
              label="Telegram"
              size="large"
              fullWidth
              error={errors?.telegram}
              name="telegram"
              initValue={values.telegram!}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <DeeplinkCopy
              label="Ссылки для автоматического перехода"
              to="teacher"
              value={values.telegram}
            />
            <Spacer size={6} />
            <DeeplinkCopy to="courses" label={false} value={values.telegram} />
          </div>
        </div>
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Описание"
          size="large"
          style={{ height: 200 }}
          fullWidth
          error={errors?.description}
          name="description"
          initValue={values.description}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Условия использования"
          size="large"
          style={{ height: 200 }}
          fullWidth
          error={errors?.termsOfUse}
          name="termsOfUse"
          initValue={values.termsOfUse || ''}
          onChange={onChangeValue}
        />
      </FormSection>
      <FormSection title="Социальные сети">
        <div className={styles.teacherForm__mainInfo}>
          <ImageUploader
            initValue={values.socialImage}
            onChange={(val) => setBatchValues({ socialImage: val })}
            uploadImageCallback={uploadImage}
          />
          <div>
            <div className="grid-2">
              <TextField
                label="Telegram"
                size="large"
                fullWidth
                name="telegramSocial"
                error={errors?.telegramSocial}
                initValue={values.telegramSocial || ''}
                onChange={onChangeValue}
              />
              <TextField
                label="Vk"
                size="large"
                fullWidth
                name="vk"
                error={errors?.vk}
                initValue={values.vk || ''}
                onChange={onChangeValue}
              />
            </div>
            <Spacer size={10} />
            <div className="grid-2">
              <TextField
                label="Facebook"
                size="large"
                fullWidth
                name="facebook"
                error={errors?.facebook}
                initValue={values.facebook || ''}
                onChange={onChangeValue}
              />
              <TextField
                label="Instagram"
                size="large"
                fullWidth
                name="instagram"
                error={errors?.instagram}
                initValue={values.instagram || ''}
                onChange={onChangeValue}
              />
            </div>
            <Spacer size={10} />
            <div className="grid-2">
              <TextField
                label="YouTube"
                size="large"
                fullWidth
                name="youtube"
                error={errors?.youtube}
                initValue={values.youtube || ''}
                onChange={onChangeValue}
              />
            </div>
          </div>
        </div>
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Текст на экране соц. сетей"
          size="large"
          style={{ height: 200 }}
          fullWidth
          name="socialsText"
          error={errors?.termsOfUse}
          initValue={values.socialsText || ''}
          onChange={onChangeValue}
        />
      </FormSection>
      <FormSection title="Платёжная информация">
        <div className="grid-3">
          <TextField
            label="ФИО"
            size="large"
            fullWidth
            error={errors?.['supplierInfo.name']}
            initValue={values.supplierInfo?.name || ''}
            onChange={(e) => updateSupplierInfo('name', e.target.value)}
          />
          <TextField
            label="Номер телефона"
            size="large"
            fullWidth
            error={errors?.['supplierInfo.phone']}
            initValue={values.supplierInfo?.phone || ''}
            onChange={(e) => updateSupplierInfo('phone', e.target.value)}
          />
          <TextField
            label="ИНН"
            size="large"
            fullWidth
            error={errors?.['supplierInfo.inn']}
            onKeyPress={handleNumberInput()}
            initValue={values.supplierInfo?.inn || ''}
            onChange={(e) => updateSupplierInfo('inn', e.target.value)}
          />
        </div>
      </FormSection>
    </div>
  );
};
