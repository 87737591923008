import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  RoleEnum,
  UpdateUserInput,
  useMeQuery,
  UserType,
  useUserQuery,
} from '@/apolloGenerated';
import {
  ActionButton,
  ActionButtons,
  ApolloErrorService,
  Container,
  createTgLink,
} from '@/shared';
import { RouterName } from '@app/router';
import { ContactHeadInfo, ContactInfoForm } from '@entities/Contact';
import { useUpdateUser } from '@features/Contact';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { UserOrders } from '@widgets/Finance';
import { Head } from '@widgets/Layout';

export interface ContactEditProps {
  params: {
    uuid: string;
  };
  query: {
    readonly?: boolean;
  };
}

export const ContactEditView: FC<ContactEditProps> = ({ params, query }) => {
  const { data: curUser } = useMeQuery();
  const { navigate } = useNavigate();
  const [updateUser, { error, loading: saveLoading }] = useUpdateUser();
  const [userData, setUserData] = useState<UpdateUserInput>();

  const { data, loading, refetch } = useUserQuery({
    variables: {
      uuid: params.uuid,
    },
  });
  const user = data?.user;
  const isCurOrAdminUser =
    curUser?.me.uuid === user?.uuid || user?.role !== RoleEnum.User;
  const isReadonly = !!query.readonly || isCurOrAdminUser;
  const tgLink = createTgLink(user?.username || '');

  const onClose = () => {
    navigate(RouterName.AdminContact);
  };
  const onSave = async () => {
    if (!userData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateUser({
      input: {
        email: userData.email,
        first_name: userData.first_name,
        gender: userData.gender,
        last_name: userData.last_name,
        phone: userData.phone,
      },
      uuid: params.uuid,
    });
    refetch();
  };

  const actions: ActionButton[] = isReadonly
    ? [
        ...(user?.username
          ? [
              {
                handler: () => window.open(tgLink),
                label: 'Чат',
              },
            ]
          : []),
        {
          handler: () =>
            navigate(RouterName.AdminContactEdit, { uuid: params.uuid }),
          label: 'Редактировать данные',
        },
      ]
    : [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: onSave,
          label: 'Сохранить изменения',
        },
      ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head
          title={`${user?.first_name} ${user?.last_name}`}
          onBack={onClose}
          rightSlot={
            <ContactHeadInfo uuid={user?.uuid} signUpDate={user?.createdAt} />
          }
        />
      </Container>
      {!isCurOrAdminUser && <ActionButtons actions={actions} />}
      <Spacer size={20} />
      <Container slim>
        {user && (
          <ContactInfoForm
            initValues={user as UserType}
            onChange={setUserData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
            readonly={isReadonly}
          />
        )}
        <Spacer size={20} />
        <UserOrders title="Покупки" uuidUser={params.uuid} />
      </Container>
    </>
  );
};
