import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import {
  createTgLink,
  LogoIcon,
  MobileLogoIcon,
  openSupportTg,
} from '@/shared';
import app_config from '@app/constants';
import { Button, Typography } from '@letsdance/ui-kit';
import phoneImg from '@shared/images/phone.png';
import { Automation, Benefit, Footer, Tasks } from '@widgets/Landing';

import './index.scss';
import styles from './styles.module.scss';

export const HomeView: FC = (): JSX.Element => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <div className="home-page">
      <header className={cx(styles.header, isDesktop && styles.container)}>
        {isDesktop ? <LogoIcon /> : <MobileLogoIcon />}
        <div className={styles.header__controls}>
          <Button variant={'outlined'} onClick={openSupportTg}>
            Связаться с нами
          </Button>
        </div>
      </header>
      <div className={styles.container}>
        <main className={styles.main}>
          <section className={styles.section}>
            <div className={styles.section__group}>
              <div>
                <div className={styles.section__list}>
                  {isDesktop ? (
                    <>
                      <Typography
                        tag={'div'}
                        variant={'body-20'}
                        className={cx(
                          styles.section__card,
                          styles.section__card_accent,
                        )}>
                        Запуск Ваших онлайн-курсов
                        <br /> в <b>Telegram</b>
                      </Typography>
                      <Typography
                        tag={'div'}
                        variant={'body-20'}
                        color={'on-surface-primary-1'}
                        className={cx(
                          styles.section__card,
                          styles.section__card_crm,
                        )}>
                        Простой и понятный
                        <br /> функционал, сделанный
                        <br /> <b>танцорами для танцоров</b>
                      </Typography>
                      <Typography
                        tag={'div'}
                        variant={'body-20'}
                        color={'on-surface-primary-1'}
                        className={cx(
                          styles.section__card,
                          styles.section__card_pay,
                        )}>
                        <b>Веб интерфейс</b> со статистикой
                        <br />и аналитикой продаж
                      </Typography>
                      <Typography
                        tag={'div'}
                        variant={'body-20'}
                        className={cx(
                          styles.section__card,
                          styles.section__card_gradient,
                        )}>
                        Прием <b>онлайн-платежей</b>
                        <br /> по всему миру
                      </Typography>
                    </>
                  ) : (
                    <div className={styles['section__card-list']}>
                      <div className={styles['section__card-wrapper']}>
                        <img
                          src={phoneImg}
                          alt="phone"
                          className={cx(styles['section__card-image'])}
                        />
                        <Typography
                          tag={'div'}
                          variant={'body-16'}
                          color={'surface-1'}
                          className={cx(
                            styles.section__card,
                            styles.section__card_accent,
                            styles.section__card_max,
                          )}>
                          <span>
                            Простой и понятный функционал, сделанный{' '}
                            <b>танцорами для танцоров</b>
                          </span>
                        </Typography>
                      </div>
                      <Typography
                        tag={'div'}
                        variant={'body-16'}
                        color={'on-surface-primary-1'}
                        className={cx(styles.section__card)}>
                        Запуск Ваших онлайн-курсов
                        <br /> в <b>Telegram</b>
                      </Typography>
                      <Typography
                        tag={'div'}
                        variant={'body-16'}
                        color={'surface-1'}
                        className={cx(
                          styles.section__card,
                          styles.section__card_accent,
                        )}>
                        Прием <b>онлайн-платежей</b> по всему миру
                      </Typography>
                      <Typography
                        tag={'div'}
                        variant={'body-16'}
                        color={'surface-1'}
                        className={cx(
                          styles.section__card,
                          styles.section__card_grey,
                        )}>
                        <b>Веб интерфейс</b> со статистикой
                        <br /> и аналитикой продаж
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles['section__main-desc']}>
                <div className={styles.section__description}>
                  <h1 className={styles.section__title}>H2Dance</h1>
                  <Typography
                    variant={'body-20'}
                    color={'on-surface-primary-1'}
                    className={styles.section__subtitle}>
                    Ваш личный <b>Telegram bot</b> для <br />
                    запусков онлайн-курсов
                  </Typography>
                </div>
                <div className={styles.section__controls}>
                  <Button
                    fullWidth={!isDesktop}
                    onClick={() =>
                      window.open(
                        createTgLink(app_config.BOT_NAME),
                        '_blank',
                        'noopener,noreferrer',
                      )
                    }
                    size={'large'}>
                    Попробовать
                  </Button>
                  {isDesktop ? (
                    <Button
                      variant={'outlined'}
                      onClick={openSupportTg}
                      size={'large'}>
                      Связаться с нами
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant={'outlined'}
                      onClick={openSupportTg}
                      size={'large'}>
                      Связаться с нами
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </section>
          <Automation />
          <Benefit />
          <Tasks />
        </main>
      </div>
      <div className={isDesktop ? styles.container : ''}>
        <Footer />
      </div>
    </div>
  );
};
