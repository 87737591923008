import React, { FC } from 'react';
import moment from 'moment-timezone';

import { TableCellLabelValue, timezone } from '@/shared';

export interface TableCellDateProps {
  timestamp: number | string;
  time?: boolean;
}
export const TableCellDate: FC<TableCellDateProps> = ({ time, timestamp }) => (
  <TableCellLabelValue
    label={moment(timestamp).tz(timezone).format('D MMM YYYY')}
    value={time ? moment(timestamp).tz(timezone).format('hh:mm') : null}
  />
);
export const renderCellDate = (args: TableCellDateProps) => (
  <TableCellDate {...args} />
);
