import { FC, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import {
  Maybe,
  OrdersListQuery,
  OrderSortKeys,
  useOrdersListQuery,
} from '@/apolloGenerated';
import {
  formatAmount,
  formatSort,
  renderCellItemValue,
  timezone,
  useTeacherId,
} from '@/shared';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

const headers = [
  { hide: true, key: true, value: 'id' },
  { sortable: true, title: 'Покупка', value: 'createdAt' },
  { title: 'Курс', value: 'course' },
  { title: 'Тариф', value: 'tariff' },
  { title: 'Потрачено, ₽ ', value: 'price' },
];
const pageSize = 10;

export interface UserOrdersTableProps {
  uuidUser: string;
  state?: boolean;
}
export const UserOrdersTable: FC<UserOrdersTableProps> = ({
  state,
  uuidUser,
}) => {
  const teacherId = useTeacherId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrderSortKeys>(OrderSortKeys.CreatedAt);

  const {
    data: curData,
    loading,
    previousData,
  } = useOrdersListQuery({
    skip: !teacherId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      isExpired: state,
      teacherId,
      uuidUser,
    },
  });
  const data = curData || previousData;

  const rowTemplate = (order: OrdersListQuery['ordersList']['rows'][0]) => ({
    course: renderCellItemValue({
      label: order.product.name,
      value: order.product.type,
    }),
    createdAt: renderCellItemValue({
      label: `ID: ${order.id}`,
      value: moment(order.createdAt).tz(timezone).format('DD MMM YYYY HH:mm'),
    }),
    id: String(order.id),
    price: formatAmount(order.price),
    tariff: order.tariff.name,
  });
  const items = useMemo(
    () => (data?.ordersList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.ordersList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as OrderSortKeys);
        }}
        notResetPage
      />
    </div>
  );
};
