import { createElement } from 'react';

import { Cart, Diagram, EmojiHappy, ReceiptAdd } from '../ui/icons';

export const AUTOMATION = [
  [
    'от',
    '12%',
    null,
    'Увеличение <br /> среднего чека',
    createElement(ReceiptAdd),
  ],
  [
    'на',
    '85%',
    null,
    'Снижение нагрузки <br /> по администрированию',
    createElement(EmojiHappy),
  ],
  ['в', '3', 'раза', 'Сокращение <br /> цикла продаж', createElement(Cart)],
  ['на', '33%', null, 'Увеличение конверсии', createElement(Diagram)],
];

export const BY_MESSENGERS = [
  'Танцоры могут самостоятельно выбрать и оплатить курс в Telegram боте',
  'Автоматический доступ к чатам и к приватным каналам с материалами',
  'Рассылка индивидуальных предложений пользователям',
];

export const STATISTIC = [
  'Управляйте базой пользователей через встроенную CRM',
  'Следите за заказами и анализируйте действия каждого участника',
];

export const LOYALTY_PROGRAM = [
  'Зарабатывайте больше с кэшбэком и рефералками для дополнительных продаж',
  'Создавайте промокоды, скидки и кэшбэк-систему',
  'Запускайте реферальные программы для танцевальных порталов и школ — привлекайте больше танцоров!',
];

export const INTEGRATION = [
  'Размещайте информацию о своих курсах на портале “Где танцевать”',
  'Получайте дополнительный трафик и привлекай новых учеников!',
];

export const ROLES = [
  [
    'Я организатор <a>фестиваля</a>',
    'и хочу автоматизировать продажи,<br /> учёт и онлайн-запись гостей',
    '/',
  ],
  [
    'Я организатор <a>вечеринки</a>',
    'и хочу автоматизировать продажи,<br /> учёт и онлайн-запись гостей',
    '/',
  ],
  ['Я организатор <a>фестиваля</a>', 'и запускаю онлайн курсы', '/'],
];
